import React, { Component } from 'react';
import team from '../images/team.jpg';

export default class Team extends Component {
  render() {
    return (
      <div className='container team-members'>
        <h3>LAAS TEAM</h3>
        <img className='rounded' src={team} alt="" style={{width:"80%"}}/>     
         </div>
    )
  }
}
