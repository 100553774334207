import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import './css/style.css';
import Slider from './pages/slider/SliderMain';
import 'bootstrap/dist/css/bootstrap.css';
import ContactUs from './pages/ContactUs';
import About from './pages/About';
import Team from './pages/Team';
import Publications from './pages/Publications';
import "./Fonts/Freehand.ttf";
import "./Fonts/BRLNSDB.TTF";
import "./Fonts/MisterEarlBTRegular.TTF";
import NavbarTop from './pages/NavbarTop';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className='navbar-main'><NavbarTop />  </div>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="aboutus" element={<About/>} />
      <Route path="publications" element={<Publications/>} />
      <Route path="/contactus" element={<ContactUs/>} />
      <Route path="team" element={<Team/>} />
      {/*<Slider />*/}
      </Routes>

    </div>
  );
}

export default App;
