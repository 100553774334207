import React, { useState } from 'react';
import {  useNavigate } from "react-router-dom";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBuildingUser, faClockRotateLeft, faFilePen, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import {Navbar, Nav, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink, UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu  } from "reactstrap";
import img from '../images/logo.png';
//import acredit from'../hospital/ar1.png';
import '../App.css';


export default function NavbarTop() {
  let navigate=useNavigate();
  const [activeTab, setActiveTab] = useState("Home");
  const [collapsed, setCollapsed] = useState(true);
   // const [isOpen, setIsOpen] = useState(false);
  //const [downcollapse, setdropcollapse]=useState(true);
    const toggleNavbar = () => {
     // console.log("clicked");
        setCollapsed(!collapsed);
    }
/*const toggledropdown = ()=>{
  setdropcollapse(!downcollapse);
}*/

const handleActiveTab = (selectedTab, event, newpath) => {
  event.preventDefault();
  setActiveTab(selectedTab);
  navigate(newpath);
}
   /* const NavbarCollapse = ()=>{
      console.log("clicked222", isOpen);

      setIsOpen(!isOpen);
    }*/

    return( 
     <Navbar dark expand="md" className='sticky-top p-0'>  
     
           <NavbarBrand href="/" style={{marginRight:'0px'}}>  
               <h3 className='nav-header-text pt-3' >    <img style={{width:"90px", marginTop:"-10px"}} src={img}   alt="LAAS-logo" />

    LEGAL ACTION ADVOCACY SERVICES (LAAS)</h3>
    </NavbarBrand>
    <div  className='navbar-bg'>
    <NavbarToggler className="navbarToggleEff me-2"  onClick={toggleNavbar} />
   
    <Collapse isOpen={!collapsed} navbar>
      <Nav className="ml-auto" pills navbar>
        <NavItem >
          <NavLink active={activeTab==="Home"?true:false} onClick={(event)=>handleActiveTab("Home", event, "/")}  >
            Home
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab==="aboutus"?true:false} onClick={(event)=>handleActiveTab("aboutus", event, "/aboutus")}  >
            About Us
          </NavLink>
        </NavItem>
       
        <NavItem>
          <NavLink active={activeTab==="team"?true:false} onClick={(event)=>handleActiveTab("team", event, "/team")}    >
            Team
          </NavLink>
        </NavItem>
       
        <NavItem>
          <NavLink active={activeTab==="publications"?true:false} onClick={(event)=>handleActiveTab("publications", event, "/publications")}   >
            Publications
          </NavLink>
        </NavItem>
            <NavItem>
          <NavLink active={activeTab==="contactus"?true:false}   onClick={(event)=>handleActiveTab("contactus", event, "/contactus")} >
          Contact Us
          </NavLink>
        </NavItem>
      </Nav> 
    </Collapse> </div>
    </Navbar>
 );
  
}
