import React, { Component } from 'react';
import icon1 from '../images/svg/asteroid.svg';
import icon2 from '../images/svg/cloud-computing.svg';
import icon3 from '../images/svg/cloud.svg';
import icon4 from '../images/svg/code.svg';
import icon5 from '../images/svg/design-tool.svg';
import icon6 from '../images/svg/paint-palette.svg';
import icon7 from '../images/svg/pixel.svg';
import icon8 from '../images/svg/planet.svg';


export default class Publications extends Component {
  render() {
    return (
      <div>
        <section id="features" className="padd-section text-center wow fadeInUp">

<div className="container">
  <div className="section-title text-center">
    <h2>Publications</h2>
    <p className="container separator">LAAS has published many books, booklets, pamphlets and other materials 
    
    to disseminate knowledge through legal literacy and awareness. Books that have been published so far: </p>
  </div>
</div>

<div className="container">
  <div className="row">

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon8} alt="img" className="img-fluid" />
        <h4>சட்டம் ஒரு இருட்டறை அல்ல</h4>
        <p></p>
      </div>
    </div>
    



    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon1} alt="img" className="img-fluid" />
        <h4>வீட்டு வன்முறைகளிலிருந்து பெண்களைப் பாதுகாக்கும் சட்டம் </h4>
         
      </div>
    </div>

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon2} alt="img" className="img-fluid" />
        <h4>சட்டத்தின் மறுபக்கம்</h4>
        
      </div>
    </div>

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon3} alt="img" className="img-fluid" />
        <h4>முதலாளிகள் உருவாக்கித் தந்த சிறப்பு பொருளாதார மண்டலச் சட்டம்</h4>
         
      </div>
    </div>

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon4} alt="img" className="img-fluid" />
        <h4>தேசிய ஊரக வேலை உத்தரவாதச் சட்டம் </h4>
         
      </div>
    </div>

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon5} alt="img" className="img-fluid" />
        <h4> 
தேசிய ஊரக வேலை உத்தரவாதச் சட்டம் 
</h4>
         
      </div>
    </div>

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon6} alt="img" className="img-fluid" />
        <h4>கொத்தடிமை ஒழிப்பும் மறுவாழ்வும் </h4>
         
      </div>
    </div>

    <div className="col-md-6 col-lg-3">
      <div className="feature-block">
        <img src={icon7} alt="img" className="img-fluid" />
        <h4>காவல்துறை</h4>
         
      </div>
    </div>

  </div>
</div>
</section>
      </div>
    )
  }
}
