import React, { Component } from 'react';
import logo from '../images/logo.png';

export default class About extends Component {
  render() {
    return (
      <div className='aboutus'>
        <h3>About Us</h3>
        {/* Lass intro ....*/}
        <div className='container d-flex justify-content-between'>

           
            <div className=' about-txt'>
                <h5>LEGAL ACTION, ADVOCACY AND SERVICES
LAAS
</h5>
                <p>Ignorantia juris neminem excusat – Ignorance of law excuses no one. Everyone is presumed to know the law, and therefore a plea of ignorance will not excuse a breach of it. There are innumerable legislations in India, to wit more than twenty-five thousand. To know all of them is highly impossible. Even a judicious judge or a learned lawyer will not be conversant with all the laws. As the traditional adage goes “law is a dark house.” </p>
            <p>For the marginalized and the illiterate, law is a strange phenomenon and human rights a mirage. Due to their ignorance, they are subject to constant human rights violations and atrocities. These need to be stopped. The affected people ought to be empowered legally and in other areas. LAAS attempts to impart basic legal and human rights awareness among the Dalits, Tribals, women and children by various means and methods. Knowledge is strength.</p>
            
            </div>
        </div>
{/*history ... */}
        <div className='container d-flex justify-content-between'>


            
            <div className=' about-txt'>
                <h5>The Beginnings
</h5>
                <p>LAAS, an acronym for Legal Action, Advocacy and Services, is a new venture of the Jesuit Madurai Province.  Established at Ulaganeri, Madurai and in the proximity of the Madurai Bench of the Madras High Court, this centre is unique in many ways. No Province as Madurai in the world can claim 11 Jesuits who have studied law. There are many social action centres all over the world but there is no centre exclusively dedicated to legal service. Moreover, there is a canonist in who offers solace and succor in canonical matters. In LAAS there are four Jesuits: Frs. Thomas Barnabas Jeyaseelan, Sahaya Philomin Raj, Stephen Kulandaisamy and Santhanam Arockiasamy. Together with other lay advocates, they are at the service of the marginalized and disadvantaged people. </p>
            <p>LAAS was officially inaugurated on January 29, 2005 by Fr. Provincial Francis Xavier. Fr. Jeyaseelan, Convenor of the Jesuit Lawyers’ Forum and Fr. Philominraj moved to a rented house in Othakadai, Madurai. However, the legal services could not proceed in full steam as the latter was busy in tsunami work in Nagapattinam district. From June 2005 onwards, Fr. Santhanam joined the team. Stichting Porticus, a donor agency in the Netherlands, extended their generosity to sponsor the programmes. LAAS with its three musketeers officially started its journey of offering legal assistance from June 12, 2005.</p>
            <p>Fr. Jeyaseelan was appointed Director of the Centre by Provincial Fr. Francis Xavier in May 2005. He was entrusted by the Province Administration with the responsibility of finding a suitable place to locate LAAS, prepare separate projects for the construction of the building and for running the programmes. The Madurai Jesuit Province, Stichting Porticus of The Netherlands, the Jesuit French Province, the Switzerland Jesuit Mission and FACSI helped to purchase land, to put up the building and to run the programmes. With a hitch here and a hitch there, LAAS Illam was built thanks to many Donors. It was formally blessed and opened by Fr. Provincial Devadoss on July 29, 2006. But the building was not fully ready for immediate occupation. It was only on September 2, 2006 that LAAS members moved to the present new building premise at Ulaganeri. </p>
            
            <p>Mention should be made here that before LAAS came into existence the genesis for the legal work commenced at IDEAS. The Jesuit lawyers first stayed and worked from IDEAS as the institute had legal assistance and intervention as one of the important objectives. The establishment of Madurai Bench of Madras High Court offered greater opportunity and called for greater legal intervention. Hence, there was a felt need to start a separate wing. The umbilical cord, however, is not snapped because IDEAS and LAAS have many collaborative endevours in training programmes, publications, issue based involvements etc. </p>
            </div>
          
        </div>

            {/* opperations ....*/}
            <div className='container d-flex justify-content-between'>


<div className=' about-txt'>
    <h5>LAreas of Operation
</h5>
    <p>LAAS seeks to offer Four >R=s, namely Reference, Resourceful, Reaching out and Remedy. People with legal problems can REFER the matter to LAAS for advice and action. It aims to be RESOURCEFUL so that the legal fraternity and others can approach LAAS for legal materials such as books, journals, computer facilities etc. It will REACH OUT to the marginalized by way of providing legal and human rights education, training and other related matters. It will also be watchful of human rights violations, atrocities against dalits, tribals, women and children and provide adequate legal assistance. If need arises, LAAS will also seek legal REMEDY by litigating in the court of law. </p>

</div>
</div>
{/*ideas ... */}
<div className='container d-flex justify-content-between'>



<div className='about-txt'>
    <h5>Linkage with IDEAS
</h5>
    <p>The journey has begun for creating a just world. It is not a bed of roses but an arduous one. With ardent and assiduous efforts, things can always be changed. IDEAS was started with this idea. Offering legal assistance was one among them. Twenty five years ago it started with a single step. Today many miles have been traversed by IDEAS. Many milestones have been achieved during these years. True, an idea can change the world. LAAS is a beacon of light that had emanated from the primordial IDEA(S). In collaboration with IDEAS, LAAS organizes training programmes in law and human rights.</p>
<p>On the occasion when IDEAS is celebrating its silver jubilee, on behalf of LAAS team I extend my prayerful wishes that it should carry on its endevours for many more years. I also congratulate all those who have worked in this place and have carried the torch of creating a better world for tomorrow. AMDG</p>
</div>

</div>



</div>


    )
  }
}
