import React from 'react'
import SliderMain from './slider/SliderMain'
import About from './About'
import Team from './Team'
import ContactUs from './ContactUs'

export default function Home() {
  return (
    <div>
        <SliderMain />
        <About />
        <Team />
        <ContactUs />
    </div>
  )
}
